@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?lr24fc");
  src: url("./assets/fonts/icomoon.eot?lr24fc#iefix") format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?lr24fc") format("truetype"),
    url("./assets/fonts/icomoon.woff?lr24fc") format("woff"),
    url("./assets/fonts/icomoon.svg?lr24fc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sent-filled:before {
  content: "\e900";
}

.icon-notify-circle:before {
  content: "\e901";
}

.icon-helper:before {
  content: "\e902";
}

.icon-summarize:before {
  content: "\e903";
}

.icon-explain-code:before {
  content: "\e904";
}

.icon-write-code:before {
  content: "\e905";
}

.icon-generate-content:before {
  content: "\e906";
}

.icon-search-2:before {
  content: "\e907";
}

.icon-write-paragraph:before {
  content: "\e908";
}

.icon-ask-question:before {
  content: "\e909";
}

.icon-status:before {
  content: "\e90a";
}

.icon-eye:before {
  content: "\e90b";
}

.icon-sent:before {
  content: "\e90c";
}

.icon-gogle:before {
  content: "\e90d";
}

.icon-clock:before {
  content: "\e90e";
}

.icon-magic:before {
  content: "\e90f";
}

.icon-copy:before {
  content: "\e910";
}

.icon-current:before {
  content: "\e911";
}

.icon-download:before {
  content: "\e914";
}

.icon-commercial:before {
  content: "\e915";
}

.icon-cart:before {
  content: "\e916";
}

.icon-timer:before {
  content: "\e917";
}

.icon-cross:before {
  content: "\e918";
}

.icon-chevron-down:before {
  content: "\e919";
}

.icon-remove:before {
  content: "\e91a";
}

.icon-add:before {
  content: "\e91b";
}

.icon-suggest:before {
  content: "\e91c";
}

.icon-burger-menu:before {
  content: "\e91d";
}

.icon-notify:before {
  content: "\e91e";
}

.icon-keep:before {
  content: "\e91f";
}

.icon-delete:before {
  content: "\e920";
}

.icon-edit:before {
  content: "\e921";
}

.icon-text-length:before {
  content: "\e922";
}

.icon-calendar:before {
  content: "\e923";
}

.icon-search:before {
  content: "\e924";
}

.icon-save:before {
  content: "\e925";
}

.icon-add-circle:before {
  content: "\e926";
}

.icon-start-generate:before {
  content: "\e927";
}

.icon-file:before {
  content: "\e928";
}

.icon-chevron-left:before {
  content: "\e929";
}

.icon-product-description:before {
  content: "\e92a";
}

.icon-captionidea:before {
  content: "\e92b";
}

.icon-google-search:before {
  content: "\e92c";
}

.icon-adds:before {
  content: "\e92d";
}

.icon-wheel:before {
  content: "\e92e";
}

.icon-cycle:before {
  content: "\e92f";
}

.icon-pictureme:before {
  content: "\e930";
}

.icon-codeme:before {
  content: "\e931";
}

.icon-chatme:before {
  content: "\e932";
}

.icon-automate:before {
  content: "\e933";
}

.icon-writer:before {
  content: "\e934";
}

.icon-article:before {
  content: "\e935";
}

.icon-project:before {
  content: "\e936";
}

.icon-dashboard:before {
  content: "\e937";
}

.icon-add-square:before {
  content: "\e938";
}

.icon-bell:before {
  content: "\e939";
}

.icon-API:before {
  content: "\e93a";
}

.icon-knowledge:before {
  content: "\e93b";
}

.icon-extension:before {
  content: "\e93c";
}

.icon-subscribe:before {
  content: "\e93d";
}

.icon-credits:before {
  content: "\e93e";
}

.icon-ssearch-optimize:before {
  content: "\e912";
}

.icon-description-file:before {
  content: "\e913";
}

.icon-text-file:before {
  content: "\e93f";
}

.icon-app-long:before {
  content: "\e940";
}

.icon-app-short:before {
  content: "\e941";
}

.icon-app-title:before {
  content: "\e942";
}

.icon-justice:before {
  content: "\e943";
}

.icon-fiver-circle:before {
  content: "\e944";
}

.icon-freelance:before {
  content: "\e945";
}

.icon-tag:before {
  content: "\e946";
}

.icon-star:before {
  content: "\e947";
}

.icon-chat:before {
  content: "\e948";
}

.icon-chat-question:before {
  content: "\e949";
}

.icon-email-sequence:before {
  content: "\e94a";
}

.icon-email:before {
  content: "\e94b";
}

.icon-chat-strole:before {
  content: "\e94c";
}

.icon-website:before {
  content: "\e94d";
}

.icon-copy-right:before {
  content: "\e94e";
}

.icon-copy-right-2:before {
  content: "\e94f";
}

.icon-call-to-action:before {
  content: "\e950";
}

.icon-sun-set:before {
  content: "\e951";
}

.icon-blog-section:before {
  content: "\e952";
}

.icon-blog-intro:before {
  content: "\e953";
}

.icon-blog-check:before {
  content: "\e954";
}

.icon-blog-topic:before {
  content: "\e955";
}

.icon-pen-stroke:before {
  content: "\e956";
}

.icon-contact:before {
  content: "\e957";
}

.icon-bulb:before {
  content: "\e958";
}

.icon-briefcase:before {
  content: "\e959";
}

.icon-diamond:before {
  content: "\e95a";
}

.icon-briefcase-stroke:before {
  content: "\e95b";
}

.icon-video-stroke:before {
  content: "\e95c";
}

.icon-speaker:before {
  content: "\e95d";
}

.icon-write-leaf:before {
  content: "\e95e";
}

.icon-magic-2:before {
  content: "\e95f";
}

.icon-music:before {
  content: "\e960";
}

.icon-poetry:before {
  content: "\e961";
}

.icon-play-button:before {
  content: "\e962";
}

.icon-play-file:before {
  content: "\e963";
}

.icon-movie:before {
  content: "\e964";
}

.icon-bell-notify:before {
  content: "\e965";
}

.icon-user-edit:before {
  content: "\e966";
}

.icon-spaceship:before {
  content: "\e967";
}

.icon-aroplane:before {
  content: "\e968";
}

.icon-car:before {
  content: "\e969";
}

.icon-return:before {
  content: "\e96a";
}

.icon-dots:before {
  content: "\e96b";
}

.icon-filter:before {
  content: "\e96c";
}

.icon-layers:before {
  content: "\e96d";
}

.icon-sent-stroke:before {
  content: "\e96e";
}

.icon-user-circle:before {
  content: "\e96f";
}

.icon-message:before {
  content: "\e970";
}

.icon-user-approve:before {
  content: "\e971";
}

.icon-forget:before {
  content: "\e972";
}

.icon-key:before {
  content: "\e973";
}

.icon-chevron-right:before {
  content: "\e974";
}

.icon-approved:before {
  content: "\e975";
}

.icon-alert:before {
  content: "\e976";
}

.icon-lock:before {
  content: "\e977";
}

.icon-hide:before {
  content: "\e978";
}

.icon-show:before {
  content: "\e979";
}

.icon-tick:before {
  content: "\e97a";
}

.icon-cross-small:before {
  content: "\e97b";
}

.icon-user-3:before {
  content: "\e97c";
}

.icon-user-add:before {
  content: "\e97d";
}

.icon-user-stroke:before {
  content: "\e97e";
}

.icon-credit-stroke:before {
  content: "\e97f";
}

.icon-credit-allocate:before {
  content: "\e980";
}

.icon-user-setting:before {
  content: "\e981";
}

.icon-key-fill:before {
  content: "\e982";
}

.icon-status-fill:before {
  content: "\e983";
}

.icon-credit-fill:before {
  content: "\e984";
}

.icon-subscribtion-fill:before {
  content: "\e985";
}

.icon-camera-fill:before {
  content: "\e986";
}

.icon-lock-fill:before {
  content: "\e987";
}

.icon-message-fill:before {
  content: "\e988";
}

.icon-user-2:before {
  content: "\e989";
}

.icon-user-1:before {
  content: "\e98a";
}

.icon-setting:before {
  content: "\e98b";
}

.icon-logout:before {
  content: "\e98c";
}

.icon-system-status:before {
  content: "\e98d";
}

.icon-feedback:before {
  content: "\e98e";
}


.icon-service24:before {
  content: "\e98f";
}

.icon-live-chat:before {
  content: "\e990";
}

.icon-translate:before {
  content: "\e991";
}

.icon-usecase:before {
  content: "\e992";
}

.icon-hint:before {
  content: "\e993";
}

.icon-gallery:before {
  content: "\e994";
}

.icon-words:before {
  content: "\e995";
}

.icon-users:before {
  content: "\e996";
}

.icon-voicing:before {
  content: "\e997";
}

.icon-forward-10:before {
  content: "\e998";
}

.icon-back-10:before {
  content: "\e999";
}

.icon-pause:before {
  content: "\e99a";
}

.icon-play:before {
  content: "\e99b";
}

.icon-voiceme:before {
  content: "\e99c";
}

.icon-chain:before {
  content: "\e99d";
}

.icon-document:before {
  content: "\e99e";
}

.icon-pictureme-image:before {
  content: "\e99f";
}

.icon-brandkit:before {
  content: "\e9a0";
}

.icon-pencil:before {
  content: "\e9a1";
}

.icon-keyboard:before {
  content: "\e9a2";
}

.icon-arrows-down-up:before {
  content: "\e901";
}

.icon-arrow-up:before {
  content: "\e9a3";
}

.icon-arrow-down:before {
  content: "\e9a4";
}


.icon-sentiment:before {
  content: "\e9a5";
}

.icon-brain:before {
  content: "\e9a6";
}