$theme-color: #552DEA;
$theme-light-color: #8D7EF8;
$light-color: #EBE5FF;
$gray-100: #667085;
$gray-200: #F2FBD2;
$gray-300: #D6DAE1;
$gray-400: #141125;
$gray-500: #C8CA9B;
$gray-600: #98A2B3;
$gray-700: #F0F1F4;
$gray-800: #5F687B;
$gray-900: #D8D8D8;


$border-color: #98A2B3;
$yellow-100: #FFBA11;
$yellow-200: #FFBB7C;
$green-100: #C2EA2E;
$green-200: #DEEF74;
$heading-color: #141125;
$badge-color: #8D7EF8;
$white: #ffffff;
$white-100: #E1F2FE;
$black: #000000;
$red-100: #ED1C24;
$red-200: #FF0F00;
$pink-100: #FFB6EA;
$pink-200: #FFB5C7;
$blue-100: #5FCFFF;
$blue-200: #0263D1;
$blue-300: #CCE6F7;
$blue-400: #3F6DCA;





$fontSize-50: 3.125rem;
$fontSize-32: 2rem;
$fontSize-30: 1.875rem;
$fontSize-28: 1.75rem;
$fontSize-24: 1.5rem;
$fontSize-22: 1.375rem;
$fontSize-20: 1.25rem;
$fontSize-18: 1.125rem;
$fontSize-17: 1.0625rem;
$fontSize-16: 1rem;
$fontSize-15: 0.9375rem;
$fontSize-14: 0.875rem;
$fontSize-12: 0.75rem;
$fontSize-11: 0.6875rem;
$fontSize-10: 0.625rem;
$fontSize-8: 0.5rem;
$fontSize-6: 0.375rem;


$border-color: $gray-300;

$border-radius: 6px;
$border-radius-md: 20px;
$border-radius-xl: 100px;
$border-circle: 50rem;

$btn-boxShadow: 0 2px 20px rgba($theme-color, 0.25);
$btn-boxShadow-lg: 0 0 40px rgba($theme-color, 0.3);
$modal-background-color: $gray-400;
$modal-boxShadow: 0 0 80px rgba($gray-400, 0.5);

$fw-normal: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

$breakpoint-max-xl: 1199.99px;
$breakpoint-max-lg: 991.99px;
$breakpoint-max-md: 767.99px;
$breakpoint-max-sm: 575.99px;
$breakpoint-max-xs: 479.99px;
$breakpoint-max-xxs: 374.99px;

$breakpoint-min-xl: 1200px;
$breakpoint-min-lg: 992px;
$breakpoint-min-md: 768px;
$breakpoint-min-sm: 576px;
$breakpoint-min-xs: 480px;
$breakpoint-min-xxs: 375px;